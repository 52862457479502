<template>
  <div>
    <v-card class="animate__animated animate__fadeInUp">
      <v-toolbar rounded="lg">
        <v-toolbar-title>
          <lord-icon
            src="https://cdn.lordicon.com/fqbvgezn.json"
            trigger="loop"
            colors="primary:#545454,secondary:#16a9c7"
            style="width: 40px; height: 40px"
          >
          </lord-icon>

          ข้อมูลจำนวนข้าราชการครูและบุคลากรทางการศึกษา(คน)
          {{ numberWithCommas(calcollegerang.countAll) }}
        </v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>

      <v-row>
        <v-col cols="12" md="6">
          <div id="chart" v-if="loading === false">
            <apexchart type="pie" :options="chartOptions" :series="series">
            </apexchart>
          </div>
        </v-col>
      </v-row>

      <v-data-table :items="college_rang" :headers="header" :search="search">
        <template v-slot:top>
          <div>
            <v-text-field
              v-model="search"
              label="ค้นหา"
              prepend-icon="mdi-magnify"
            ></v-text-field>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'DashboardOvecMasterCollegeRang',

  data() {
    return {
      search: '',
      loading: true,
      college_rang: [],
      header: [
        { text: 'วิทยาลัย', align: 'left', value: 'college_rang_name' },
        { text: 'ข้าราชการ', align: 'center', value: 'college_rang_all' },
        { text: 'ครูผู้ช่วย', align: 'center', value: 'college_rang_0' },
        { text: 'คศ.1', align: 'center', value: 'college_rang_1' },
        { text: 'คศ.2', align: 'center', value: 'college_rang_2' },
        { text: 'คศ.3', align: 'center', value: 'college_rang_3' },
        { text: 'คศ.4', align: 'center', value: 'college_rang_4' },
        { text: 'คศ.5', align: 'center', value: 'college_rang_5' },
      ],
      series: [],
      chartOptions: {
        chart: {
          type: 'pie',
        },
      },
      calcollegerang: [],
    }
  },

  async mounted() {
    await this.college_rang_Qid()
  },

  methods: {
    numberWithCommas(x) {
      x = String(x)
      var pattern = /(-?\d+)(\d{3})/
      while (pattern.test(x)) x = x.replace(pattern, '$1,$2')
      return x
    },


    async college_rang_Qid() {
      try {
        this.loading = true
        let result = await this.$http.post('college_rang_id.php', {
          college_id: this.college_ID,
        })
        this.college_rang = result.data
        let data = this.college_rang
        let countAll = 0
        let countAll0 = 0
        let countAll1 = 0
        let countAll2 = 0
        let countAll3 = 0
        let countAll4 = 0
        let countAll5 = 0

        data.forEach(async (value) => {
          countAll += parseInt(value.college_rang_all)
        })

        data.forEach((value) => {
          countAll0 += parseInt(value.college_rang_0)
          countAll1 += parseInt(value.college_rang_1)
          countAll2 += parseInt(value.college_rang_2)
          countAll3 += parseInt(value.college_rang_3)
          countAll4 += parseInt(value.college_rang_4)
          countAll5 += parseInt(value.college_rang_5)
        })

        this.series = [
          countAll0,
          countAll1,
          countAll2,
          countAll3,
          countAll4,
          countAll5,
        ]
        this.chartOptions = {
          chart: {
            type: 'pie',
            width: 400,
          },
          labels: [
            'ครูผู้ช่วย : ' + this.numberWithCommas(countAll0),
            'ครู : ' + this.numberWithCommas(countAll1),
            'ชำนาญการ : ' + this.numberWithCommas(countAll2),
            'ชำนาญการพิเศษ : ' + this.numberWithCommas(countAll3),
            'เชี่ยวชาญ : ' + this.numberWithCommas(countAll4),
            'เชี่ยวชาญพิเศษ : ' + this.numberWithCommas(countAll5),
          ],
        }

        this.calcollegerang.countAll = countAll
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
  },

  computed: {
    college_ID(){
      return this.$route.query.college_ID
    }
  },
 
}
</script>
