<template>
  <div>
    <v-card
      :loading="loading"
      rounded="lg"
      class="animate__animated animate__fadeInUp"
      v-if="personnel_etc_groupAll > 0"
    >
      <v-toolbar class="elevation-0">
        <v-toolbar-title>
          <lord-icon
            src="https://cdn.lordicon.com/fqbvgezn.json"
            trigger="loop"
            colors="primary:#545454,secondary:#16a9c7"
            style="width: 50px; height: 50px"
          >
          </lord-icon>
          อัตรากำลัง (คน)
          {{ numberWithCommas(personnel_etc_groupAll) }}
        </v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>

      <div id="chart" v-if="loading === false">
        <apexchart type="pie" :options="chartOptions" :series="series">
        </apexchart>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "OcsMasterMouchartdonut",

  data() {
    return {
      loading: false,

      personnel_etc_groups: [],
      series: [],
      personnel_etc_groupAll: [],
      chartOptions: {
        chart: {
          type: "pie"
        }
      }
    };
  },

  async mounted() {
    await this.personnel_etc_groupQueryID();
  },

  methods: {
    numberWithCommas(x) {
      x = String(x);
      var pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
      return x;
    },

    async personnel_etc_groupQueryID() {
      this.loading = true;
      let result = await this.$http
        .post("personnel_etc_group_id.php", {
          ApiKey: this.ApiKey,
          college_id: this.college_ID
        })
        .finally(() => (this.loading = false));
      this.personnel_etc_groups = result.data;
      let labelText = [];
      let valuesst = [];
      let counts = 0;
      let m_type;
      let data = this.personnel_etc_groups;
      data.forEach(async value => {
        counts += parseInt(value.countGrouptype);

        labelText.push(
          value.user_status_name +
            " : " +
            this.numberWithCommas(value.countGrouptype)
        );
        valuesst.push(
          parseFloat((value.countGrouptype * 100) / value.countAll).toFixed(2)
        );
      });

      this.personnel_etc_groupAll = counts;

      let valueshowaf = valuesst.map(i => Number(i));
      this.series = valueshowaf;

      this.chartOptions = {
        chart: {
          type: "pie"
        },

        labels: labelText,

        dataLabels: {
          formatter(val, opts) {
            return [val.toFixed(1) + "%"];
          }
        }
      };
    }
  },

  computed: {
    college_ID() {
      return this.$route.query.college_ID;
    }
  },
  watch: {}
};
</script>
