<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" md="3">
        <collegeDirector />
        <CollegeStd02 />
        <mapThaigis />
      </v-col>
      <v-col cols="12" md="9">
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pa-1">
            <ManPowerHrvec/>
          </v-col>
          <v-col cols="12" md="6" class="pa-1">
            <StdLevel/>
          </v-col>
          <v-col cols="12" class="pa-1">
            <StdBranch/>
          </v-col>
          <v-col cols="12" class="pa-1">
            <Collegemajor/>
            </v-col>
          <v-col cols="12" class="pa-1">
            <Stdminor/>
          </v-col>
          <v-col cols="12" class="pa-1">
            <Personnel_etc_id/>
          </v-col>
          <v-col cols="12" class="pa-1">
            <College_rang/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import collegeDirector from "@/views/dashboard/components/collegeinfo/collegeDirector.vue";
import CollegeStd02 from "../components/collegeinfo/collegeStd02.vue";
import mapThaigis from "@/views/dashboard/components/collegeinfo/mapThaigis.vue";
import ManPowerHrvec from "../components/collegeinfo/manPowerHrvec.vue";
import StdLevel from "../components/collegeinfo/stdLevel.vue";
import StdBranch from "../components/collegeinfo/stdBranch.vue";
import Stdminor from "../components/collegeinfo/stdminor.vue";
import Personnel_etc_id from "../components/collegeinfo/personnel_etc_id.vue";
import College_rang from "../components/collegeinfo/college_rang.vue";
import Collegemajor from "../components/collegeinfo/collegemajor.vue";
export default {
  components: {
    collegeDirector,
    CollegeStd02,
    mapThaigis,
    ManPowerHrvec,
    StdLevel,
    StdBranch,
    Stdminor,
    Personnel_etc_id,
    College_rang,
    Collegemajor,
  },
  name: "CollegesId",
  data() {
    return {
      // Add your component data here
    };
  },
  methods: {
    // Add your component methods here
  },
  mounted() {
    // Add your mounted logic here
  }
};
</script>

<style scoped>
.collegesid {
  /* Add your component styles here */
}
</style>
