<template>
  <div>
    <v-card :loading="loading" outlined>
      <div class="text-center font-weight-bold mb-2"></div>
      <div id="chartdiv"></div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "DashboardOvecMasterMapThaigis",

  data() {
    return {
      ApiKey:"HRvec2021",
      loading: false,
      areas: [],
      countCollege: "",
      textMaps: "",
      textMapsid: "",
      textMapsids: "",
      clickIDMap: ""
    };
  },

  async mounted() {
    await this.fetchstdProvinceid();
    await this.mapThai();
  },

  methods: {
    numberWithCommas(x) {
      x = String(x);
      var pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
      return x;
    },

    async fetchstdProvinceid() {
      this.loading = true;
      let result = await this.$http
        .post("province_sh.php", {
          ApiKey: this.ApiKey,
          province_ID: this.college_ID
        })
        .finally(() => (this.loading = false));

      let data = result.data;
 
      let arr = [];
      arr.push({
        id: "TH" + "-" + data.province_ID,
        title: data.province_name,
        value: data.countcollege
      });

      this.areas = arr;
      this.textMapsid = data.province_ID;
      await this.mapThai();
    },

    async mapThai() {
      var map = AmCharts.makeChart("chartdiv", {
        type: "map",
        theme: "light",
        colorSteps: 10,
        hideCredits: true,
        dataProvider: {
          map: "thailandLow",
          areas: this.areas
        },

        areasSettings: {
          autoZoom: true,
          rollOverOutlineColor: "#FFFFFF",
          rollOverColor: "#CC0000",
          alpha: 0.8,
          unlistedAreasAlpha: 0.1,
          balloonText: "[[title]] [[value]] วิทยาลัย"
        },
        export: {
          enabled: false
        }
      });

      map.addListener("clickMapObject", function callClick(event) {
        document.getElementById("textMap").value = event.mapObject.title;
        document.getElementById("idMap").value = event.mapObject.id;
        document.getElementById("idMap").innerHTML = event.mapObject.id;
      });
    }
  },

  computed: {
    college_ID() {
      let newItems = String(this.$route.query.college_ID);
      let newslice = newItems.slice(2, 4);
      return newslice;
    }
  },
  watch: {}
};
</script>

<style scoped>
#chartdiv {
  width: 100%;
  height: 600px;
}
</style>
