<template>
  <div>
    <v-card
      :loading="loading"
      v-if="countAllstd > 0 && loading === false"
      rounded="lg"
      class="animate__animated animate__fadeInUp pa-1"
    >
      <v-toolbar class="elevation-0">
        <v-toolbar-title>
          <lord-icon
            src="https://cdn.lordicon.com/sclmgjsa.json"
            trigger="loop"
            colors="primary:#545454,secondary:#16a9c7"
            style="width: 50px; height: 50px"
          >
          </lord-icon>
          ครูผู้สอนแยกตามสาขาวิชา : {{ countAllstd }} คน
        </v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>

      <div id="chart">
        <apexchart
          type="bar"
          height="380"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'DashboardOvecMasterStdBranch',

  data() {
    return {
      loading: true,
      series: [],
      chartOptions: {
        chart: {
          type: 'bar',
        },
      },
      countAllstd: '',
    }
  },

  async mounted() {
    await this.personnel_etc_idQuery()
  },

  methods: {
    numberWithCommas(x) {
      x = String(x)
      var pattern = /(-?\d+)(\d{3})/
      while (pattern.test(x)) x = x.replace(pattern, '$1,$2')
      return x
    },

    async personnel_etc_idQuery() {
      try {
        this.loading = true
        let result = await this.$http.post('personnel_etc_id.php', {
          college_id: this.college_ID,
        })
        let data = result.data
        let labelText = []
        let valuesst = []
        let countall = 0
        data.forEach((value) => {
          countall += parseInt(value.countB)

          let id_course_branch = String(value.id_course_branch)
          let id_course_branchSl = id_course_branch.slice(0, 1)
          let classlevel
          if (id_course_branchSl == '2') {
            classlevel = 'ปวช.'
          } else {
            classlevel = 'ปวส.'
          }
          labelText.push(
            value.branch_name + ' ' + classlevel + ' : ' + value.countB
          )
          valuesst.push(value.countB)
        })
        this.countAllstd = countall
        let valueshowaf = valuesst.map((i) => Number(i))
        this.series = [
          {
            data: valueshowaf,
          },
        ]

        this.chartOptions = {
          chart: {
            type: 'bar',
            height: 380,
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              barHeight: '100%',
              distributed: true,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            },
          },

          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val
            },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ['#304758'],
            },
          },

          stroke: {
            width: 1,
            colors: ['#fff'],
          },

          xaxis: {
            categories: labelText,
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              formatter: function (val) {
                return val
              },
            },
          },

          tooltip: {
            theme: 'dark',
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function () {
                  return ''
                },
              },
            },
          },
        }
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
  },

  computed: {
    college_ID() {
      return this.$route.query.college_ID
    },
  },
 
}
</script>
