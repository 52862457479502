<template>
  <div>
    <v-card
      :loading="loading"
      v-if="countAll > 0 && loading === false"
      rounded="lg"
      class="animate__animated animate__fadeInUp"
    >
      <v-toolbar class="elevation-0">
        <v-toolbar-title>
          <lord-icon
            src="https://cdn.lordicon.com/fqbvgezn.json"
            trigger="loop"
            colors="primary:#545454,secondary:#16a9c7"
            style="width: 40px; height: 40px"
          >
          </lord-icon
          >เปิดสอน สาขาวิชา
        </v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>

      <v-data-table
        :items="rate_work_college_search"
        :headers="header"
        :search="search"
      >
        <template v-slot:top>
          <div>
            <v-text-field
              v-model="search"
              label="ค้นหา"
              prepend-icon="mdi-magnify"
            ></v-text-field>
          </div>
        </template>
        <template v-slot:item.action_search="{ item }">
          <div>
            <v-btn @click="handleClick(item.college_code)" icon>
              <lord-icon
                src="https://cdn.lordicon.com/wjyqkiew.json"
                trigger="loop"
                colors="primary:#545454,secondary:#16a9c7"
                style="width: 40px; height: 40px"
              >
              </lord-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'DashboardOvecMasterCollegeRang',

  data() {
    return {
      ApiKey: "HRvec2021",

      loading: true,
      search: '',
      rate_work_college_search: [],
      header: [
      { text: 'ประเภทวิชา', align: 'center', value: 'type_course' },
      { text: 'สาขาวิชา', align: 'center', value: 'branch_name_th' },
        { text: 'ระดับ', align: 'center', value: 'rate_work_college_class' },
        {
          text: 'ปวช.1/ปวส.1',
          align: 'center',
          value: 'rate_work_field_study_lavel_1',
        },
        {
          text: 'ปวช.2/ปวส.2',
          align: 'center',
          value: 'rate_work_field_study_lavel_2',
        },
        {
          text: 'ปวช.3',
          align: 'center',
          value: 'rate_work_field_study_lavel_3',
        },
        { text: 'รวม', align: 'center', value: 'stdnum' },
      ],
      countAll: '',
      year:2567,
    }
  },

  async mounted() {
    await this.rate_work_college_searchQuery()
  },

  methods: {
    async rate_work_college_searchQuery() {
      try {
        this.loading = true
        let result = await this.$http
          .post('rate_work_college.php', {
            ApiKey: this.ApiKey,
            rate_work_college_code: this.college_ID,
            showbranch: '1',
          })
          .finally(() => (this.loading = false))
        this.rate_work_college_search = result.data
    
        let data = this.rate_work_college_search
        let countAll = 0
        data.forEach((value) => {
          countAll += 1
        })
        this.countAll = countAll
      } catch (error) {
      } finally {
        this.loading = false
      }
    },  
  },

  computed: {
    college_ID(){
      return this.$route.query.college_ID
    }
  },
  watch: {
  
  },
}
</script>
