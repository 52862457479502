<template>
  <div>
    <!-- -->
    <v-card   
    :loading="loading" 
      rounded="lg"
      class="animate__animated animate__fadeInUp"
    >
      <v-toolbar class="elevation-0">
        <v-toolbar-title>
          <lord-icon
            src="https://cdn.lordicon.com/fqbvgezn.json"
            trigger="loop"
            colors="primary:#545454,secondary:#16a9c7"
            style="width: 40px; height: 40px"
          >
          </lord-icon>
          ผู้บริหารสถานศึกษา
        </v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>

      <v-row>
        <v-col cols="12" md="6">
          <div class="pa-1">
            <div class="text-center font-weight-bold mt-5">
              {{ collegeinfo.collegeinfo_director }}
            </div>
            <div class="text-center">
              ผู้อำนวยการ{{ collegeinfo.college_name }}
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="pa-1">
            <v-img
              class="img-radius"
              height="200"
              contain
              :src="
                'https://hrvec.ovec.go.th/HRvecfiles/' +
                collegeinfo.collegeinfo_directorpic
              "
            ></v-img>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'DashboardOvecMasterCollegeDirector',

  data() {
    return {
      ApiKey: "HRvec2021",
      collegeinfo: [],
      loading: true,
    }
  },

  async mounted() {
    await this.collegeinfoQuery()
  },

  methods: {
    async collegeinfoQuery() {
      try {
        this.loading = true
        let result = await this.$http
          .post('collegeinfo.php', {
            ApiKey: this.ApiKey,
            collegeinfo_code: this.college_ID,
          })      
        this.collegeinfo = result.data
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
  },
  computed: {
    college_ID() {
      return this.$route.query.college_ID
    },  
  },
 
}
</script>
