<template>
  <div>
    <v-card
      :loading="loading"
      rounded="lg"
      class="animate__animated animate__fadeInUp"
    >
      <v-toolbar class="elevation-0">
        <v-toolbar-title>
          <lord-icon
            src="https://cdn.lordicon.com/cfoaotmk.json"
            trigger="loop"
            colors="primary:#545454,secondary:#16a9c7"
            style="width: 50px; height: 50px"
          >
          </lord-icon>
          ผู้เรียนแยกตามประเภทวิชา (คน)
          {{ numberWithCommas(countAllstd) }}
        </v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>

      <div id="chart" v-if="loading === false">
        <apexchart
          type="bar"
          height="450"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'DashboardOvecMasterStdBranch',

  data() {
    return {
      loading: true,
      series: [],
      chartOptions: {
        chart: {
          type: 'bar',
        },
      },
      countAllstd: '',
    }
  },

  async mounted() {
    await this.rate_work_college_std_id()
  },

  methods: {
    numberWithCommas(x) {
      x = String(x)
      var pattern = /(-?\d+)(\d{3})/
      while (pattern.test(x)) x = x.replace(pattern, '$1,$2')
      return x
    },

  
    async rate_work_college_std_id() {
      try {
        this.loading = true
        let result = await this.$http
          .post('rate_work_college_std_id.php', {
            college_id: this.college_ID,
          })
          .finally(() => (this.loading = false))
        let data = result.data
        let labelText = []
        let valuesst = []
        let countall = 0
        data.forEach((value) => {
          countall += parseInt(value.countSumg)
          labelText.push(value.subject_typeName)
          valuesst.push(value.countSumg)
        })
        this.countAllstd = countall
        let valueshowaf = valuesst.map((i) => Number(i))
        this.series = [
          {
            data: valueshowaf,
          },
        ]

        this.chartOptions = {
          chart: {
            type: 'bar',
            height: 450,
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              barHeight: '100%',
              distributed: true,
              horizontal: true,
              dataLabels: {
                position: 'bottom',
              },
            },
          },
          colors: [
            '#33b2df',
            '#546E7A',
            '#d4526e',
            '#13d8aa',
            '#A5978B',
            '#2b908f',
            '#f9a3a4',
            '#90ee7e',
            '#f48024',
            '#69d2e7',
            '#3EE529',
            '#E565F2',
          ],
          dataLabels: {
            enabled: true,
            textAnchor: 'start',
            style: {
              ontSize: '12px',
              colors: ['#000000'],
            },
            formatter: function (val, opt) {
              return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val
            },
            offsetX: 50,
            dropShadow: {
              enabled: false,
            },
          },
          stroke: {
            width: 1,
            colors: ['#fff'],
          },
          xaxis: {
            categories: labelText,
          },
          yaxis: {
            labels: {
              show: false,
            },
          },

          tooltip: {
            theme: 'dark',
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function () {
                  return ''
                },
              },
            },
          },
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        this.loading = false
      }
    },
  },

  computed: {
    college_ID(){
      return this.$route.query.college_ID
    }
  },

}
</script>
