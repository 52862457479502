<template>
  <div>
    <v-card
      :loading="loading"
      rounded="lg"
      class="animate__animated animate__fadeInUp pa-1"
    >
      <div>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <td>
                  <lord-icon
                    src="https://cdn.lordicon.com/vvyxyrur.json"
                    trigger="loop"
                    colors="primary:#545454,secondary:#16a9c7"
                    style="width: 40px; height: 40px"
                  >
                  </lord-icon>
                </td>
                <td>{{ std02college.schoolName }}</td>
              </tr>
              <tr>
                <td>
                  <lord-icon
                    src="https://cdn.lordicon.com/onmwuuox.json"
                    trigger="loop"
                    colors="primary:#545454,secondary:#16a9c7"
                    style="width: 40px; height: 40px"
                  >
                  </lord-icon>
                </td>
                <td>
                  {{ std02college.houseNumber }} หมู่
                  {{ std02college.villageNumber }} ซอย
                  {{ std02college.soi }} ถนน {{ std02college.street }} ต.
                  {{ std02college.tumbolCode }} รหัสไปรษณีย์
                  {{ std02college.zipcode }}
                </td>
              </tr>
              <tr>
                <td>
                  <lord-icon
                    src="https://cdn.lordicon.com/vcdutftw.json"
                    trigger="loop"
                    colors="primary:#545454,secondary:#16a9c7"
                    style="width: 40px; height: 40px"
                  >
                  </lord-icon>
                </td>
                <td>{{ std02college.telephone }}</td>
              </tr>
              <tr>
                <td>
                  <lord-icon
                    src="https://cdn.lordicon.com/vpbspaec.json"
                    trigger="loop"
                    colors="primary:#545454,secondary:#16a9c7"
                    style="width: 40px; height: 40px"
                  >
                  </lord-icon>
                </td>
                <td>{{ std02college.email }}</td>
              </tr>
              <tr>
                <td>
                  <lord-icon
                    src="https://cdn.lordicon.com/fjvfsqea.json"
                    trigger="loop"
                    colors="primary:#545454,secondary:#16a9c7"
                    style="width: 40px; height: 40px"
                  >
                  </lord-icon>
                </td>
                <td>{{ std02college.fax }}</td>
              </tr>
              <tr>
                <td>
                  <lord-icon
                    src="https://cdn.lordicon.com/rpviwvwn.json"
                    trigger="loop"
                    colors="primary:#545454,secondary:#16a9c7"
                    style="width: 40px; height: 40px"
                  >
                  </lord-icon>
                </td>
                <td>
                  <v-btn
                    color="info"
                    @click="weblink(std02college.website)"
                    text
                    >{{ std02college.website }}</v-btn
                  >
                  <v-btn
                    icon
                    small
                    @click="weblink(std02college.website)"
                    color="info"
                  >
                    <v-icon>mdi-link-circle</v-icon></v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'DashboardOvecMasterCollegeStd02',

  data() {
    return {
      std02college: [],
      std02college: [],
      loading:true,
    }
  },

  async mounted() {
    await this.std02collegeQuery()
  },

  methods: {
    async weblink(website) {
      let textlink = String(website)
      let httpck = textlink.slice(0, 4)
      if (httpck == 'www.') {
        window.open('http://' + website, '_blank').focus()
      } else {
        window.open(website, '_blank').focus()
      }
    },
    async std02collegeQuery() {
      try{
        this.loading=true  
        let result = await this.$http.post('std02college.php', {
        SchoolID: this.college_ID,
      })
      this.std02college = result.data.SchoolDetail
      }catch(error){

      }finally{
        this.loading=false
      }
    
    },
  },
  computed: {
    college_ID(){
      return this.$route.query.college_ID
    }
  },
  
}
</script>
