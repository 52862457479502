<template>
  <div>
    <v-card
      :loading="loading"
      rounded="lg"
      class="animate__animated animate__fadeInUp"
    >
      <v-toolbar class="elevation-0">
        <v-toolbar-title>
          <lord-icon
            src="https://cdn.lordicon.com/cfoaotmk.json"
            trigger="loop"
            colors="primary:#545454,secondary:#16a9c7"
            style="width: 50px; height: 50px"
          >
          </lord-icon>
          ผู้เรียน (คน)
          {{ numberWithCommas(rate_work_colleges.TotalAll) }}
        </v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>
      <div id="chart" v-if="loading === false">
        <apexchart type="pie" :options="chartOptions" :series="series">
        </apexchart>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'OcsMasterMouchartdonut',

  data() {
    return {
      loading: true,
      usersystems: [],
      rate_work_colleges: [],
      countsdata: '',
      series: [],
      rate_work_collegeAll: [],
      chartOptions: {
        chart: {
          type: 'pie',
        },
      },
    }
  },

  async mounted() {
    await this.rate_work_collegeQuery_id()
  },

  methods: {
    numberWithCommas(x) {
      x = String(x)
      var pattern = /(-?\d+)(\d{3})/
      while (pattern.test(x)) x = x.replace(pattern, '$1,$2')
      return x
    },

    
    async rate_work_collegeQuery_id() {
      try {
        this.loading=true
        let result = await this.$http
          .post('rate_work_college_id.php', {
            ApiKey: this.ApiKey,
            college_id: this.college_ID,
          })
          .finally(() => (this.loading = false))
        this.rate_work_colleges = result.data
        let data = this.rate_work_colleges
        let ch1 = parseFloat((data.ch1 * 100) / data.TotalAll)
        let ch2 = parseFloat((data.ch2 * 100) / data.TotalAll)
        let ch3 = parseFloat((data.ch3 * 100) / data.TotalAll)
        let s1 = parseFloat((data.s1 * 100) / data.TotalAll)
        let s2 = parseFloat((data.s2 * 100) / data.TotalAll)
        let t1 = parseFloat((data.t1 * 100) / data.TotalAll)
        let t2 = parseFloat((data.t2 * 100) / data.TotalAll)
        this.series = [ch1, ch2, ch3, s1, s2, t1, t2]
        this.chartOptions = {
          chart: {
            type: 'pie',         
          },
          labels: [
            'ปวช.1 : ' + this.numberWithCommas(data.ch1),
            'ปวช.2 : ' + this.numberWithCommas(data.ch2),
            'ปวช.3 : ' + this.numberWithCommas(data.ch3),
            'ปวส.1 : ' + this.numberWithCommas(data.s1),
            'ปวส.2 : ' + this.numberWithCommas(data.s2),
            'ทล.บ.1 : ' + this.numberWithCommas(data.t1),
            'ทล.บ.2 : ' + this.numberWithCommas(data.t2),
          ],
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        this.loading = false
      }
    },
  },

  computed: {
    college_ID(){
      return this.$route.query.college_ID
    }
  },
  
}
</script>
